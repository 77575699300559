<div class="navbar-area">
    <div class="container">
        <nav class="navbar navbar-expand-md navbar-light">
            <a class="navbar-brand" routerLink="/">
                <img src="assets/img/logo.png" alt="logo">
            </a>

            <button class="navbar-toggler" type="button">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse">
                <ul class="navbar-nav">
                    <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home</a></li>
                    <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/about" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About Us</a></li>
                    <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/services-one" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Services</a></li>
                    <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/projects-one" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Products</a></li>
                    <!-- <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/projects-two" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Projects</a></li> -->
                    <!-- <li class="nav-item"><a routerLink="/" class="nav-link">Pages <i class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/about" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About Us</a></li>

                            <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/team" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Team</a></li>

                            <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/pricing" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Pricing</a></li>

                            <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/faq" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">FAQ</a></li>

                            <li class="nav-item"><a routerLink="/" class="nav-link">User</a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/log-in" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Log In</a></li>

                                    <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/sign-up" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Sign Up</a></li>
                                </ul>
                            </li>

                            <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/terms-conditions" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Terms & Conditions</a></li>

                            <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/privacy-policy" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Privacy Policy</a></li>
                        
                            <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/error" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">404 Error</a></li>

                            <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/coming-soon" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Coming Soon</a></li>

                            <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                        </ul>
                    </li> -->
                    <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                </ul>
                <div class="others-options">
                    <div class="option-item"><i class="search-btn flaticon-search"></i>
                        <i class="close-btn flaticon-cancel"></i>
                        <div class="search-overlay search-popup">
                            <div class='search-box'>
                                <form class="search-form">
                                    <input class="search-input" name="search" placeholder="Search" type="text">
                                    <button class="search-button" type="submit"><i class="flaticon-search"></i></button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="burger-menu">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </div>
        </nav>
    </div>
</div>